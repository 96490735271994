//Css
import './App.css';

import grid from './components/grid';

//Updating comments again
const title = 'Holmestrand bodhotell'

function App() {
  
  return (
    <div className='App'>
      {grid()}
    </div>
  );
}

export default App;
