import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import './grid.css';
import './form.css';


const REACT_APP_EMAILJSID = process.env.REACT_APP_EMAILJSID ?? 'null';
const REACT_APP_TEMPLATEIDBOOKING = process.env.REACT_APP_TEMPLATEIDBOOKING ?? 'null';
const REACT_APP_PUBLICKEY = process.env.REACT_APP_PUBLICKEY ?? 'null';



export const ContactUs = () => {
  const form = useRef<HTMLFormElement>(null);

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

      emailjs.sendForm(REACT_APP_EMAILJSID, REACT_APP_TEMPLATEIDBOOKING, form.current!, REACT_APP_PUBLICKEY)
          .then((result) => {
                  console.log(result.text);
                  window.alert("Takk for din henvendelse, vi vil ta kontakt med deg så fort som mulig.");
                  
          }, (error) => {
                  console.log(error.text);
                  window.alert("Det oppstod en feil, vennligst prøv igjen.");

          });

          e.currentTarget.reset();
};

  return (
    <form ref={form} onSubmit={sendEmail} className='gridBox'>
        <h2>Send oss en hendvendelse her:</h2>
      
        <label>Navn:  </label>
        <input type="text" name="user_name" required/> 
        
        <label>Din epost adresse:  </label>
        <input type="email" name="user_email" required/>
        
        <label>Telefonnummer:  </label>
        <input type="number" name="phone"  required/>
        
        <label>Ønsket leieperiode:  </label>
        <input type = "text" required/>
        
        <label>Ønsket type bod:  </label>
        <p><label><input type="radio" name="bodType" value="Liten" required/> Liten bod 3.5 kvadratmeter</label></p>
        <p><label><input type="radio" name="bodType" value="Midddels" required/> Middels 5 kvadratmeter bod</label></p>
        <p><label><input type="radio" name="bodType" value="Stor" required/> Stor bod 10 kvadratmeter</label></p>
        
        <label>Annet:  </label>
        <input type="text" name="subject" placeholder="Kom med eventuell tilleggsinformasjon og/eller krav"/>
        
      <input type="submit" value="Send" />
    </form>
  );
};

export default ContactUs;